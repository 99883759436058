import { Component, Prop, Mixins, namespace, Watch } from '@zento-lib/components';
import { Overlay } from '@zento/modules/atom/Overlay/Overlay';
import type { IZentoTheme } from '@zento-common/lib/config/types/zento/theme';

import { AppContextStore, KEY as appContextKey } from '../../@types/zento/stores/applicationContext';

import { FormField } from './FormField';
import { ValidatorOneOf } from './types';
import { ValidationMechanisms } from './validation/mechanisms';
import { ValidationMessage } from './Message';
import { Input } from './Input';
import { SelectList } from './SelectList';
import { ISelect } from './Select.d';
import style from './style.scss';

type SelectPropagationEventType = 'change';

// Defines the different style types for filters
export type FiltersStyle = 'radio';

const appContextStore = namespace<AppContextStore>(appContextKey);

@Component({})
export class Select<T extends Record<string | number, any>> extends Mixins<
  FormField<string, SelectPropagationEventType, ISelect<any>, any>
>(FormField) {
  /**
   * Check if the validator oneOf exists in the context of current field instance
   */
  protected static validatorOneOfExtraction(instance: Select<any>): ValidatorOneOf<any> | null {
    // Compute the parameters
    return {
      value: instance.items,
      labelKeeper: instance.references.label,
      valueKeeper: instance.references.value,
    };
  }

  protected validationRules = {
    [FormField.RequiredRuleName]: FormField.validatorRequiredExtraction,
    oneOf: Select.validatorOneOfExtraction,
  };

  protected validationMechanisms = {
    [FormField.RequiredRuleName]: ValidationMechanisms.string.required,
    oneOf: ValidationMechanisms.string.oneOf,
  };

  protected acceptPartialLabel = false;

  protected lastValue: string;

  /**
   * Collection of all items
   */
  @Prop({ type: Array, default: () => [] })
  items: T[];

  /**
   * Determines the properties being used to resolve the item's value and label
   */
  @Prop({ type: Object, default: () => ({ label: 'label', value: 'value' }) })
  references: { value: ValidatorOneOf<T>['valueKeeper']; label: ValidatorOneOf<T>['labelKeeper'] };

  /**
   * Allow the select to filter items
   */
  @Prop({ type: Boolean, default: true })
  filter?: boolean;

  /**
   * Allow the select to filter items by value
   */
  @Prop({ type: Boolean, default: false })
  filterOnValue?: boolean;

  /**
   * Input placeholder property
   */
  @Prop({ type: String, required: false, default: '' })
  placeholder?: string;

  /**
   * Get data style
   */
  @Prop({ type: String, required: false })
  styleType?: FiltersStyle;

  /**
   * Set input default value if found
   */
  @Prop({ type: Boolean, required: false, default: false })
  getDefault?: boolean;

  /**
   * Label style
   */
  @Prop({ type: String, default: '' })
  labelStyle?: IZentoTheme['labelStyle'];

  /**
   * Determines selected icon
   */
  @Prop({ type: Boolean, default: false })
  selectedIcon: boolean;

  /**
   * Determines data test id used for qa test
   */
  @Prop({ type: String, default: '' })
  dataTestId?: string;

  /**
   * Determines if input is editable
   */
  @Prop({ type: Boolean, default: false })
  readOnly?: boolean;

  /**
   * Determines if input have helper text for mobile
   */
  @Prop({ type: Boolean, default: false })
  helperTextMobile?: boolean;

  // Item value to item label lookup map
  private lookupMap: Record<string, string | number>;

  private uniqueId = Math.floor(Math.random() * 10000);

  @appContextStore.Getter('isTablet')
  isTablet: boolean;

  @appContextStore.Getter('isDesktop')
  isDesktop: boolean;

  @Watch('options', { deep: true, immediate: true })
  onItemsChanged(items) {
    // Recompute the items lookup map
    this.computelookupMap(items);

    this.$data.filteredItems = this.filterItems();

    // Recompute dynamic validation rules (oneOf)
    (this as any).extractRules();
  }

  @Watch('input', { deep: true, immediate: true })
  onInputChanged() {
    this.$data.filteredItems = this.filterItems();
  }

  data() {
    // Compute the items lookup map for the first time
    this.computelookupMap(this.items);

    return {
      input: this.getLabel(this.getFieldValue()),
      value: this.getFieldValue(),
      isVisible: false,
      filteredItems: [],
      options: this.items,
    } as any;
  }

  created() {
    // Compute the items lookup map for the first time
    this.computelookupMap(this.items);

    this.lastValue = '';
  }

  beforeMount() {
    // Pre bind item data getter
    this.itemData = this.itemData.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onOverlayClose = this.onOverlayClose.bind(this);

    this.$watch('items', (items) => {
      this.$data.options = items;
      this.computelookupMap(items);
      this.refreshInputValue(this.getDefault);
    });
  }

  beforeUpdate() {
    this.refreshInputValue();
  }

  refreshInputValue(refresh = false) {
    if (this.lastValue !== this.$data.value) {
      const determined = this.getLabel(this.$data.value);
      let changed = false;

      if (this.acceptPartialLabel) {
        if (determined !== undefined) {
          this.$data.input = determined;
          changed = true;
        }
      } else {
        this.$data.input = determined;
        changed = true;
      }

      this.lastValue = this.$data.value;

      if (changed) {
        const input = this.$el.querySelector('input');

        if (input && determined !== undefined) {
          // Force currently visible DOM element to the determined label
          input.value = determined as string;
        }
      }
    } else if (refresh && this.$refs.innerInput) {
      // Enforce label on same value change in case the user modified the input contents
      this.lastValue = '';
      this.refreshInputValue();
    }
  }

  get computedLabelStyle() {
    return this.labelStyle || this.extended.$config.zento.theme.labelStyle;
  }

  get labelPosition() {
    switch (true) {
      case this.computedLabelStyle.includes('top'):
        return style.labelTop;

      // case this.computedLabelStyle.includes('left'):
      //   return style.labelLeft;

      // case this.computedLabelStyle.includes('right'):
      //   return style.labelRight;

      // case this.computedLabelStyle.includes('bottom'):
      //   return style.labelBottom;
    }
  }

  public render() {
    const pristine = this.isPristine();
    const valid = this.isValid();
    const hasValidation = this.activeRules.length > 0;

    return (
      <div
        class={{
          [style.select]: true,
          [style.selectWrapperActive]: this.$data.isVisible,
          [style.radio]: this.styleType === 'radio',
        }}>
        <ValidationMessage
          class={style.selectBox}
          state={this.validationState}
          validationParams={this.rules}
          type='string'
          key='select'>
          <div
            class={{
              [style.selectLabelBox]: true,
              [style.inputValid]: !pristine && valid && hasValidation,
              [style.inputError]: !pristine && !valid && hasValidation,
              [style.inputWrapper]:
                (this.computedLabelStyle.includes('animated') || this.computedLabelStyle.includes('static')) &&
                (this.$data.value || this.$data.isVisible),
            }}>
            <div
              class={{
                [style.selectLabel]: true,
                [style.mobileStyle]: !this.isDesktop && this.helperTextMobile,
              }}
              onClick={this.handleFocus}
              onFocusOut={this.handleFocusOut}
              key='label'>
              {!this.isDesktop ? (
                <span
                  class={{
                    [style.beforeLabel]: true,
                    [style.animateLabel]: this.computedLabelStyle.includes('animated'),
                    [style.staticLabel]: this.computedLabelStyle.includes('static'),
                    [this.labelPosition]: true,
                  }}>
                  <slot name='label' />
                  {this.required ? <em>*</em> : null}
                </span>
              ) : null}
              <span class={style.currentValue} key='currentValue'>
                {this.$data.input}
              </span>
            </div>
            {!this.isDesktop && this.helperTextMobile ? (
              <span class={[style.helperText, style.helperTextMobile]}>{this.$slots.after}</span>
            ) : null}

            <transition
              name='slide-in-down'
              enterActiveClass={style.slideInDownEnterActive}
              leaveActiveClass={style.slideInDownLeaveActive}
              key='wrapper'>
              <div
                v-show={this.$data.isVisible}
                class={{
                  [style.selectWrapper]: true,
                  [style.selectActive]: this.$data.isVisible,
                }}>
                <div class={style.modalHeader} key='modal'>
                  <slot name='label' />
                  <div class={style.closeBtn} key='close'>
                    <button type='button' class={style.button} onClick={this.closeModal} />
                  </div>
                </div>

                <div onFocusOut={this.handleFocusOut} key='select-box'>
                  <div class={style.selectBox}>
                    <Input
                      state={this.$data}
                      valueKeeper='input'
                      name={'innerInput-' + this.uniqueId}
                      class={style.dropdownValue}
                      validateOn='input'
                      key={this.lastValue}
                      placeholder={this.placeholder}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      embedded={true}
                      pristinePolicy={this.pristinePolicy}
                      autocomplete='off'
                      readOnly={this.readOnly}
                      dataTestId={this.dataTestId}
                      ref='innerInput'>
                      {this.isDesktop ? (
                        <span slot='label'>
                          {this.$slots.label}
                          {this.required ? <em>*</em> : null}
                        </span>
                      ) : null}
                      {this.$slots.after && (pristine || valid) ? <span slot='after'>{this.$slots.after}</span> : null}
                    </Input>
                  </div>
                </div>

                <div class={style.dropdownList} onClick={this.closeModal} key='item-list'>
                  <SelectList
                    items={this.$data.filteredItems}
                    itemData={this.itemData}
                    selectedIcon={this.selectedIcon}
                  />
                </div>
              </div>
            </transition>
          </div>

          {this.$data.isVisible === true ? (
            <span onClick_capture={this.onOverlayClose} key='overlay'>
              <Overlay class={style.showOverlay} />
            </span>
          ) : null}
        </ValidationMessage>
      </div>
    );
  }

  /**
   * Close the overlay and modal
   */
  private onOverlayClose() {
    this.closeModal();
  }

  /**
   * Close the modal
   */
  private closeModal() {
    this.$data.isVisible = false;

    // if (!this.isDesktop) {
    //   document.body.style.overflow = 'visible';
    // }
  }

  /**
   * Open the list on mobile mode
   */
  private handleFocus() {
    this.$data.isVisible = true;

    // if (!this.isDesktop) {
    //   document.body.style.overflow = 'hidden';
    // }
  }

  private handleBlur() {
    let knownOptionEnforced = false;
    const matched = this.findSelectValue(
      this.$data.input,
      this.$data.options,
      this.filterOnValue ? this.references.value : this.references.label,
    );

    if (matched.length) {
      const valueKeeper = this.references.value;
      const matchedElement = matched.find((m) => m[valueKeeper] === this.$data.value);
      const current = this.$data.value ? (matchedElement !== undefined ? matchedElement : null) : null;

      if (!current) {
        // The curent value of the input is different from the previously selected option,
        // but options with that value were found.
        // Enforce the value of the first option into the select
        this.$data.value = matched.sort((a, b) => a[valueKeeper] - b[valueKeeper])[0][valueKeeper];
        knownOptionEnforced = true;
      } else if (this.$data.input !== current[this.references.label]) {
        // Enforce the selected option label on the input
        this.$data.input = current[this.references.label];
      }
    } else {
      // No matches, enforce the current input value as select value to allow invalidation
      this.$data.value = this.$data.input;
    }

    this.propagateValue('change');

    // Propagate event to other interested parties
    this.propagateEvent({
      type: 'change',
      target: { value: this.$data.value },
    } as any);

    this.$data.isVisible = false;

    if (knownOptionEnforced) {
      // Ensure input value reflects the value's label on no change selections
      this.refreshInputValue(true);
    }
  }

  /**
   * Close the list on mobile mode
   */
  private handleFocusOut() {
    setTimeout(() => {
      this.$data.isVisible = false;
    }, 200);
  }

  /**
   * Renders a single option of the select's items collection
   */
  private itemData(i: T) {
    const labelKeeper = this.references.label;
    const valueKeeper = this.references.value;

    return {
      content: i[labelKeeper],
      attrs: {
        class: i[labelKeeper] === this.$data.input ? style.selected : [],
        'data-testid': this.removeSpecialChars(i[labelKeeper], '-'),
      },
      on: {
        mousedown: () => {
          this.$data.value = i[valueKeeper];
          this.propagateValue('change');

          // Propagate event to other interested parties
          this.propagateEvent({
            type: 'change',
            target: { value: this.$data.value },
          } as any);

          this.$data.isVisible = false;

          // Ensure input value reflects the value's label on no change selections
          this.refreshInputValue(true);
        },
      },
    };
  }

  /**
   * Filter items by current input value (if in search mode)
   */
  protected filterItems(): T[] {
    const key = this.filterOnValue ? this.references.value : this.references.label;
    const inputValue = this.$data.input;
    const searchMode = this.filter && inputValue !== this.getLabel(this.fieldValue);

    if (searchMode) {
      return this.findSelectValue(inputValue, this.$data.options, key);
    }

    return this.$data.options;
  }

  private replaceDiacritics(str: string) {
    if (str !== undefined && str !== null) {
      const normalize = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      return normalize;
    }

    return '';
  }

  private normalizedContains(i: string, needle: string) {
    const regExp = new RegExp(this.replaceDiacritics(needle), 'gi');

    return regExp.test(this.replaceDiacritics(i));
  }

  private findSelectValue(strToMatch: string, items: T[], key: keyof T) {
    return items.filter((i) => this.normalizedContains(i[key], strToMatch));
  }

  /**
   * Computes the items lookup map
   */
  private computelookupMap(items: T[] = this.$data.options) {
    this.lookupMap = {};

    const valueKeeper = this.references.value;
    const labelKeeper = this.references.label;

    items.forEach((i) => {
      if (!(valueKeeper in i)) {
        // The item has no value
        throw new Error(`Select: Missing property ${valueKeeper} in select option item ${JSON.stringify(i)}.`);
      } else if (!(labelKeeper in i)) {
        // The item has no label
        throw new Error(`Select: Missing property ${labelKeeper} in select option item ${JSON.stringify(i)}.`);
      }

      if (this.lookupMap[i[valueKeeper]]) {
        throw new Error(
          `Select: Duplicated value '${i[valueKeeper]}' in select option items ${JSON.stringify(items)}.`,
        );
      }

      this.lookupMap[i[valueKeeper]] = i[labelKeeper];

      // TODO: Refine, should work without this verification
      if (this.acceptPartialLabel && this.state.locationAutocomplete === i[valueKeeper]) {
        this.$data.input = i[labelKeeper];
      }
    });
  }

  /**
   * Determines the label associated with a particular item
   */
  private getLabel(value: any) {
    return this.lookupMap[value];
  }

  /**
   * Remove special characters for rendering correct value
   */
  private removeSpecialChars(value, char) {
    const fieldValue = value.replace(/[^a-zA-Z0-9]/g, char);

    return fieldValue;
  }
}
