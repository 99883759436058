import { BaseComponent, Prop, Component } from '@zento-lib/components';

import type { ISelectList } from './SelectList.d';
import style from './style.scss';

export interface IItem {
  content: JSX.Element;
  props: Record<string, any>;
}

@Component({})
export class SelectList<T> extends BaseComponent<ISelectList<T>, unknown> {
  /**
   * Collection of items to be rendered
   */
  @Prop({ type: Array, default: () => [] })
  items: T[];

  /**
   * Item renderer
   */
  @Prop({ type: Function, default: (i: T) => JSON.stringify(i) })
  itemData: (item: T) => IItem;

  /**
   * Determines selected icon
   */
  @Prop({ type: Boolean, default: false })
  selectedIcon: boolean;

  render() {
    return (
      <div
        class={{
          [style.dropdownSelect]: true,
          [style.selectedIcon]: this.selectedIcon,
        }}>
        <ul data-testid='autocomplete' data-selected={this.selectedIcon}>
          {this.items.map((i) => {
            const itemData = this.itemData(i);

            return this.$createElement('li', itemData || {}, [itemData.content || null]);
          })}
        </ul>
      </div>
    );
  }
}
